@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/_mixins.scss';

* {
  box-sizing: border-box;
}
html {
  overflow-x: auto;
}
body {
  color: #262626;
  margin: auto;
  overflow-wrap: break-word;
  word-wrap: break-word;
}
body ul,
body ol,
body li {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
body p {
  margin: 0;
  padding: 0;
}
body a,
body img {
  display: initial;
}
body img {
  width: initial;
}
body h1,
body h2,
body h3,
body h4,
body h5,
body h6 {
  margin: 0;
}

$primary: #ffc300;
$secondary: #eb4637;
$headingColor: #ffdb66;
$categoryLinkColor: #ffcf33;

.inner-container {
  margin: 0 auto;
  @include media-breakpoint-up(md) {
    max-width: 960px;
  }
  @include media-breakpoint-up(lg) {
    width: 960px;
  }
  &.is-instruction {
    border-top: 1px solid #dce0e0;
    padding: 1.25rem 0.75rem 0;
    @include media-breakpoint-only(md) {
      margin: 0 auto;
      width: 640px;
    }
    @include media-breakpoint-up(lg) {
      margin: 0 auto;
      width: 640px;
    }
  }
}

.section-area {
  .inner-container {
    max-width: 960px;
    margin: 0 auto;
  }
  &.section-bg-main {
    background: #f3f5f5;
  }
}
.white-space-nowrap {
  white-space: nowrap;
}
.pagetop {
  opacity: 0;
  transform: translate3d(0, 150%, 0);
  bottom: 30px;
  width: 60px;
  height: 60px;
  position: fixed;
  right: 30px;
  z-index: 2;
  border: 1px solid #dce0e0;
  border-radius: 50%;
  box-shadow: 0 1px 0 #dce0e0;
  transition: opacity 0.2s linear, transform 0.6s cubic-bezier(0.19, 1, 0.22, 1),
    -webkit-transform 0.6s cubic-bezier(0.19, 1, 0.22, 1);
  &:hover {
    opacity: 0.6;
  }
  &::before {
    content: '';
    width: 22px;
    height: 19px;
    position: absolute;
    top: -3px;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
  }
  &.pagetop-show {
    opacity: 1;
    transform: translateZ(0);
  }
}
.main-heading {
  align-items: center;
  background: #fff3cc;
  display: flex;
  font-family: Hiragino Sans, 'ヒラギノ角ゴシック', Hiragino Kaku Gothic ProN,
    'ヒラギノ角ゴ ProN W3', sans-serif;
  font-size: 1.375rem;
  font-weight: 700;
  justify-content: center;
  margin: 0;
  min-height: 100px;
  @include media-breakpoint-up(lg) {
    margin: 0 0 4rem;
  }
}

.heading-bold {
  font-size: 18px;
  line-height: 1.38889;
}

.fap_contact {
  margin-top: 40px;
}
.content-text {
  margin: 20px 0 0;
}
.grid-area {
  flex-wrap: wrap;
  margin: -20px -10px 0;
  > li {
    padding: 20px 10px 0;
  }
}
.fap-contactlist {
  margin-top: 20px;
  dt {
    margin-bottom: 10px;
  }
}
.btn {
  width: 100%;
  padding: 0 4px;
  border-radius: 5px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  line-height: 1.21429;
  transition: opacity 0.2s ease-out;
  cursor: pointer;
  > div {
    height: 48px;
  }
}
.icon-line {
  width: 42px;
  height: 42px;
  margin: 0 4px 0 0;
}
.c-logo--carmo,
.c-logo--carmo-white,
.c-logo--line {
  font-size: 0;
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: cover;
}
.content-body-list {
  padding: 0 30px;
}
.list {
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem;
  @include media-breakpoint-up(md) {
    gap: 0.75rem 1.5rem;
    justify-content: space-between;
  }
  > li {
    align-items: center;
    background: $categoryLinkColor;
    border-radius: 8px;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
      0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    display: flex;
    justify-content: center;
    min-height: 64px;
    position: relative;
    width: calc(50% - 0.375rem);
    @include media-breakpoint-up(md) {
      width: calc(50% - 0.75rem);
    }
    &::after {
      background: url(./assets/img/icon-chevron-down.svg) 50% 50% no-repeat;
      background-size: cover;
      bottom: 0.5rem;
      content: '';
      display: inline-block;
      height: 6px;
      position: absolute;
      width: 16px;
    }
  }
  li a[href*='section-support'] {
    .list-text {
      font-size: 0.6875rem;
      line-height: 1.3;
      padding: 0 0.5rem 0.5rem;
    }
  }
  &-text {
    color: #262626;
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 1.3;
    padding: 0 0.75rem 0.5rem;
    text-align: center;
    width: 100%;
  }
  &-button {
    padding: 0;
  }
}
body .list-text {
  line-height: 1.3;
}
.list-button {
  align-items: center;
  display: flex;
  height: 100%;
  position: relative;
  text-decoration: none;
  width: 100%;
  .list-text {
    font-weight: 700;
    position: relative;
  }
}
.list-button-navigation,
.list-button-news {
  width: 100%;
  min-height: 50px;
  padding: 12px 40px 12px 0;
  color: inherit;
  transition: opacity 0.2s ease-out;
}
.ico-arrow,
.ico-arrow-back,
.icon-arrow-down {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  &:before {
    content: '';
    width: 0;
    height: 0;
    border: solid transparent;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
  }
}
.icon-arrow-down:before {
  border-width: 6px 4px 0;
  right: 0;
  left: 0;
}
.list-arrow,
.list-arrow-down {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  margin: auto;
}
.list-arrow,
.list-arrow-down,
.list-arrow-left {
  position: absolute;
  z-index: 1;
  margin: auto;
}
.list-arrow-down:before {
  border-top-color: #c8cccb;
}
.list-text {
  line-height: 1.78571;
  font-size: 14px;
}
.faq-heading {
  align-items: center;
  background: $headingColor;
  display: flex;
  font-size: 1.15rem;
  font-weight: 600;
  justify-content: center;
  margin-bottom: 0;
  min-height: 3rem;
  padding: 0.75rem;
  position: relative;
  text-align: center;
  z-index: 20;
}
.faq-container {
  background: #fff;
  margin: 0 0 0.75rem;
  padding: 2rem 0.75rem;
  position: relative;
  z-index: 10;
  @include media-breakpoint-up(md) {
    padding: 2rem 0;
  }
  &.is-category {
    @include media-breakpoint-only(md) {
      margin: 0 auto;
      max-width: 640px;
      padding: 2rem 0;
    }
    @include media-breakpoint-up(md) {
      margin: 0 auto 2rem;
      padding: 2rem 0;
      .list {
        margin: 0 auto;
        width: 640px;
      }
    }
  }
  &.is-instruction {
    padding: 1.25rem 0.75rem 2rem;
  }
}
.faq-list {
  @include media-breakpoint-up(md) {
    margin: 0 auto;
    width: 640px;
  }
}
.faq-contact {
  &-container {
    padding: 2rem 0;
  }
  &-card {
    background: #fff;
    border-radius: 10px;
    box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.14),
      0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
    margin: 0 1rem 2rem;
    padding: 2rem 0.75rem;
    text-align: center;
    &:last-of-type {
      margin: 0 1rem;
      @include media-breakpoint-only(md) {
        margin: 0 auto 1rem;
        width: 640px;
      }
      @include media-breakpoint-up(md) {
        margin: 0 auto 1rem;
      }
    }
    @include media-breakpoint-only(md) {
      margin: 0 auto 2rem;
      width: 640px;
    }
    @include media-breakpoint-up(md) {
      margin: 0 auto 2rem;
      width: 640px;
    }
  }
  &-heading {
    font-size: 1rem;
    font-weight: 700;
    margin: 0 auto 1.5rem;
  }
  &-link-tel,
  &-link-mail,
  &-link-line {
    display: block;
    margin: 0 auto;
    width: 312px;
    @media screen and (max-width: 374px) {
      max-width: 100%;
    }
  }
  &-image-mail,
  &-image-line {
    @media screen and (max-width: 374px) {
      max-width: 100%;
    }
  }
  &-text {
    display: block;
    margin: 2rem auto 0;
    text-align: center;
    &-min {
      display: block;
      font-size: 0.6875rem;
      margin: 1rem auto 0;
      text-align: center;
    }
  }
}
.faq-category-link {
  cursor: pointer;
  display: block;
  font-size: 0.75rem;
  margin: 1rem 0 0 auto;
  position: relative;
  text-decoration: underline;
  width: fit-content;
  @include media-breakpoint-up(md) {
    margin: 1rem 9.25rem 0 auto;
  }
  &::after {
    background-size: cover;
    content: '';
    display: inline-block;
    height: calc(0.75rem / 2);
    margin: 0 0 0 0.5rem;
    width: 0.75rem;
  }
}
.faq-container-helper {
  @include media-breakpoint-up(md) {
    padding-bottom: 2rem;
  }
}
.faq-charm-container-helper {
  @include media-breakpoint-up(md) {
    margin: 0 auto;
    width: 920px;
  }
}
.faq-foot-container {
  background: $primary;
  border-bottom: 2rem solid #262626;
  margin: 0;
  padding: 2rem 0.75rem 1rem;
  position: relative;
  z-index: 10;
  @include media-breakpoint-up(md) {
    border-bottom: none;
    margin: 0 auto 2rem;
    padding: 2rem 0.75rem;
    width: 920px;
  }
  &-body {
    @include media-breakpoint-up(md) {
      margin: 0 auto;
      width: 640px;
    }
  }
}
.faq-foot-banner-container {
  margin: 0 auto 2.5rem;
}
.has-underline {
  text-decoration: underline;
}

.keyword {
  &-input-container {
    display: flex;
    margin: 0 0 1.5rem;
    position: relative;
    width: 100%;
    @include media-breakpoint-only(md) {
      margin: 0 auto 1.5rem;
      max-width: 640px;
    }
    @include media-breakpoint-up(md) {
      margin: 0 auto 1.5rem;
      width: 640px;
    }
    &::before {
      background: url(./assets/img/icon-search-glass-grey.svg) 0 0 no-repeat;
      background-size: cover;
      content: '';
      display: inline-block;
      height: 18px;
      left: 0.625rem;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 18px;
      z-index: 20;
    }
  }
  &-input-field {
    border: 1px solid #bdbdbd;
    border-radius: 4px;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
      0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    font-size: 0.9375rem;
    min-height: 48px;
    padding: 0.5rem 0 0.5rem 2.25rem;
    width: 100%;
    z-index: 10;
    &:focus,
    &:focus-within {
      outline: 0;
    }
  }
  &-input-button {
    background-color: $primary;
    background-image: url(./assets/img/icon-search-glass-black.svg);
    background-position: 50% 50%;
    background-repeat: no-repeat;
    border: none;
    border-radius: 4px;
    height: 46px;
    position: absolute;
    right: 1px;
    top: 1px;
    width: 46px;
    z-index: 20;
  }
  &-tip-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 0.875rem 0.5rem;
    @include media-breakpoint-up(md) {
      gap: 0.875rem 0.75rem;
      margin: 0 auto;
      max-width: 480px;
    }
  }
  &-tip-item {
    background: #e9e9e9;
    border-radius: 9999px;
    cursor: pointer;
    font-size: 0.75rem;
    padding: 0.25rem 0.75rem;
    width: fit-content;
  }
}
// 末尾コンテンツsection系統
.section {
  &-header {
    min-height: 44px;
    @include media-breakpoint-up(md) {
      min-height: 52px;
    }
    &-logo {
      transition: opacity 0.3s;
      &:hover {
        cursor: pointer;
        opacity: 0.7;
      }
    }
  }
  &-heading {
    font-weight: 700;
    min-height: 5.25rem;
    position: relative;
    width: 100%;
    &.is-first {
      &::before {
        background: initial;
        content: '定額カルモくん認定中古車とは';
        display: inline-block;
        left: initial;
        position: absolute;
        top: initial;
        -webkit-text-stroke: initial;
        z-index: 50;
      }
    }
    &.is-second {
      &::before {
        background: initial;
        content: 'ご契約から納車までの流れ';
        display: inline-block;
        left: initial;
        position: absolute;
        top: initial;
        -webkit-text-stroke: initial;
        z-index: 50;
      }
    }
    &.is-third {
      &::before {
        background: initial;
        content: 'よくあるご質問';
        display: inline-block;
        left: initial;
        position: absolute;
        top: initial;
        -webkit-text-stroke: initial;
        z-index: 50;
      }
    }
    &.is-fourth {
      &::before {
        background: initial;
        content: 'お申し込みタイプ';
        display: inline-block;
        left: initial;
        position: absolute;
        top: initial;
        -webkit-text-stroke: initial;
        z-index: 50;
      }
    }
    @include media-breakpoint-only(sm) {
      min-height: 8.25rem;
    }
    @media screen and (min-width: 1201px) {
      min-height: 6.25rem;
    }
    @media screen and (min-width: 1401px) {
      min-height: 7.25rem;
    }
    @media screen and (max-width: 320px) {
      font-size: 5.867vw;
    }
    &.is-inquiry {
      -webkit-text-stroke: initial;
      padding: 0 0 0 3rem;
      position: relative;
      &::before {
        content: '';
        display: inline-block;
        height: 25.6vw;
        left: 2rem;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 20.8vw;
        @include media-breakpoint-only(sm) {
          height: 96px;
          left: calc(50% - 11.5rem);
          width: 78px;
        }
        @include media-breakpoint-up(md) {
          height: 96px;
          left: calc(50% - 11.5rem);
          width: 78px;
        }
        @include media-breakpoint-up(lg) {
          left: calc(50% - 19rem);
        }
      }
    }
    &.has-button-to-lineup {
      background: initial;
      color: #ff0000;
      font-size: 5.867vw;
      min-height: initial;
      -webkit-text-stroke: initial;
      @include media-breakpoint-up(md) {
        font-size: 1.375rem;
      }
    }
  }
  &-subheading {
    height: 44px;
    font-weight: 700;
    margin: 0 auto 2rem;
    &.is-multiline {
      line-height: 1.1;
      margin: 2rem auto 3.5rem;
      text-align: center;
    }
    @include media-breakpoint-up(md) {
      width: 380px;
    }
    @media screen and (max-width: 320px) {
      font-size: 5.867vw;
    }
    &::before,
    &::after {
      display: inline-block;
      content: '';
      height: 44px;
      position: absolute;
      width: 20px;
    }
    &::before {
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
    &::after {
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
    &-underline {
      bottom: 0;
      display: block;
      content: '';
      height: 3px;
      left: 50%;
      position: absolute;
      transform: translateX(-50%);
      width: calc(100% - 56px);
      &.is-multiline {
        bottom: -1rem;
      }
    }
  }
  &-heading-fourth {
    display: block;
    font-size: 1.25rem;
    font-weight: 700;
    margin: 2rem auto 1.5rem;
    text-align: center;
  }
  &-body {
    padding: 1.75rem 1.75rem 3rem;
    a {
      transition: opacity 0.3s;
      &:hover {
        cursor: pointer;
        opacity: 0.7;
      }
    }
    @include media-breakpoint-up(md) {
      gap: 0.5rem;
      margin: 0 auto;
      padding: 3rem 1.75rem 3rem;
      width: 720px;
    }
    @include media-breakpoint-up(lg) {
      gap: 0.5rem;
      margin: 0 auto;
      width: 960px;
    }
    @include media-breakpoint-up(xl) {
      margin: 0 auto;
      width: 1080px;
    }
    &.is-step {
      @include media-breakpoint-up(md) {
        width: 760px;
      }
    }
    &.is-navigation {
      background: $primary;
      min-height: initial;
      padding: 37.333vw 0 2rem;
      @media screen and (min-width: 577px) and (max-width: 767px) {
        padding: 5rem 0 2rem;
      }
      @include media-breakpoint-up(md) {
        min-height: 160px;
        padding: 5rem 0 2rem;
        width: 100%;
      }
    }
    &.is-qa {
      @include media-breakpoint-up(md) {
        width: 760px;
      }
    }
    &.is-inquiry {
      padding: 1rem 1.75rem 3rem;
      @include media-breakpoint-up(md) {
        width: 760px;
      }
    }
    &.is-ordertype {
      background: #fff;
      padding: 1.75rem 0 3rem;
      @include media-breakpoint-up(md) {
        padding: 3rem 1.75rem 0;
      }
    }
    &.has-button-to-lineup {
      @include media-breakpoint-up(md) {
        background: #fff9e6;
        margin: 0 auto 0;
        width: 100%;
      }
    }
  }
  &-text-brown {
    font-size: 1.0625rem;
  }
}

// ordertype
.ordertype {
  &-caption {
    @include media-breakpoint-up(md) {
      margin: 0 auto;
      width: 760px;
    }
  }
  &-notice {
    color: #777;
    display: block;
    font-size: 0.75rem;
    margin: 0.75rem auto 0;
  }
  &-list-container {
    @include media-breakpoint-up(lg) {
      display: flex;
      gap: 0 2.5rem;
      justify-content: center;
    }
  }
  &-list-item {
    margin: 0 auto;
    &:first-of-type {
      border-bottom: 1px solid #787878;
      width: 94.667vw; // 355px
    }
    @include media-breakpoint-up(lg) {
      margin: 3rem 0 0;
      width: 32%;
    }
    &:last-of-type {
      margin: 0 auto;
      @include media-breakpoint-up(lg) {
        margin: 3rem 0 0;
      }
    }
  }
  &-list-child {
    background: #fff;
    border-radius: 0.625rem;
    &.is-unused {
      border: 1px solid #001751;
    }
  }
  &-list-divide-first {
    background: linear-gradient(90deg, #eecf2a 0%, #fffcec 50%, #eecf2a 100%);
    font-weight: 700;
    padding: 2.25rem 0 1.125rem;
    position: relative;
    text-align: center;
    &::before {
      // background: url(../img/img-balloon-list-divide-red.png) 0 0 no-repeat;
      background-size: cover;
      content: '';
      display: block;
      height: 11.733vw;
      left: 50%;
      position: absolute;
      top: calc((-11.733vw / 2) + 10px);
      transform: translateX(-50%);
      width: 80vw;
      @include media-breakpoint-up(md) {
        height: 44px;
        top: calc((44px / 2) + 10px);
        width: 300px;
      }
    }
  }
  &-list-divide {
    background: linear-gradient(90deg, #001751 0%, #00113a 50%, #001751 100%);
    color: #fff;
    font-weight: 700;
    padding: 2.25rem 0 1.125rem;
    position: relative;
    text-align: center;
    &::before {
      background-size: cover;
      content: '';
      display: block;
      height: 11.733vw;
      left: 50%;
      position: absolute;
      top: calc((-11.733vw / 2) + 10px);
      transform: translateX(-50%);
      width: 89.333vw;
      @include media-breakpoint-up(md) {
        height: 44px;
        top: calc((44px / 2) + 10px);
        width: 335px;
      }
    }
  }
  &-name {
    background: linear-gradient(to bottom, transparent 65%, #eecf2a 66%);
    display: block;
    font-size: 5.333vw; // 20px
    font-weight: 700;
    margin: 0 auto;
    padding: 1.25rem 0 0;
    position: relative;
    text-align: center;
    width: 74.667vw; // 280px
    @include media-breakpoint-up(md) {
      font-size: 2.25rem;
      padding: 2.75rem 0 0;
      width: 280px;
    }
    &.is-second {
      @include media-breakpoint-up(md) {
        font-size: 2.25rem;
        letter-spacing: -0.05rem;
        padding: 2.75rem 0 0;
      }
    }
  }
  &-name-min {
    font-size: 1.5rem;
    font-weight: 600;
  }
  &-feature {
    display: flex;
    justify-content: center;
    gap: 0.5rem;
    left: 50%;
    position: absolute;
    top: -1.125rem;
    transform: translateX(-50%);
    z-index: 10;
  }
  &-feature-tip {
    align-items: center;
    background: #001751;
    border-radius: 9999px;
    color: #fff;
    display: block;
    font-size: 1.0625rem;
    font-weight: 400;
    height: initial;
    justify-content: center;
    line-height: 2;
    padding: 0 1.25rem;
    width: max-content;
    @include media-breakpoint-up(md) {
      letter-spacing: initial;
    }
  }
  &-description {
    margin: 0 auto;
    &.is-unused {
      margin: 0 0 1.25rem;
    }
    &.is-first {
      @include media-breakpoint-up(lg) {
        min-height: 96px;
      }
    }
  }
  &-subheding {
    font-size: 4.267vw;
    font-weight: 700;
    margin: 0 auto 1rem;
    padding: 0 0 0.125rem;
    text-align: center;
    @include media-breakpoint-only(md) {
      font-size: 1rem;
    }
    @include media-breakpoint-only(lg) {
      font-size: 1rem;
    }
    @include media-breakpoint-up(xl) {
      font-size: 1.0625rem;
    }
  }
  &-middle-container {
    margin: 0 auto 1.5rem;
    padding: 0 1.75rem;
    position: relative;
    &.is-third {
      @include media-breakpoint-up(xl) {
        border-width: 0 10.5625rem 2.25rem;
      }
    }
  }
  &-figure {
    margin: 1rem auto;
    padding: 0 1.75rem;
    @include media-breakpoint-up(md) {
      align-items: center;
      display: flex;
      min-height: 136px;
      max-height: 136px;
    }
  }
  &-figure-image {
    @include media-breakpoint-up(md) {
      max-height: 136px;
      object-fit: contain;
    }
  }
  &-ablelist {
    &-container {
      min-height: 100px;
      &.is-suv {
        min-height: 48px;
        @include media-breakpoint-up(md) {
          min-height: 100px;
        }
      }
    }
    &-item {
      border-bottom: 1px dashed #838383;
      font-weight: 300;
      height: fit-content;
      line-height: 2;
      width: 100%;
      &:nth-of-type(3n) {
        border: none;
      }
    }
  }
  &-price {
    &-wrapper {
      background: #fff;
      border-bottom-left-radius: 0.625rem;
      border-bottom-right-radius: 0.625rem;
      overflow: hidden;
      padding: 0 1rem 1.5rem;
      @include media-breakpoint-up(md) {
        padding: 0 1rem 1rem;
      }
      &.is-unused {
        background: linear-gradient(
          90deg,
          #001751 0%,
          #00113a 48.44%,
          #00113a 53.65%,
          #001751 100%
        );
      }
    }
  }
  &-button {
    align-items: center;
    background: #ff0000;
    border: 2px solid #ffffff;
    box-shadow: 0px 4px 0px rgba(0, 0, 0, 0.4);
    border-radius: 80px;
    color: #fff;
    cursor: pointer;
    display: flex;
    font-size: 4.533vw; // 17px
    font-weight: 400;
    height: 55px;
    justify-content: center;
    line-height: 1.25;
    margin: 0 auto 1.5rem;
    position: relative;
    text-align: center;
    text-decoration: none;
    width: 89.333vw; // 335px
    @include media-breakpoint-only(md) {
      font-size: 1.125rem;
    }
    @include media-breakpoint-only(lg) {
      font-size: 1.125rem;
    }
    @include media-breakpoint-up(xl) {
      font-size: 1.25rem;
    }
    @media screen and (max-width: 320px) {
      font-size: 4.267vw;
    }
    &.is-second {
      @include media-breakpoint-only(md) {
        padding: 0 0.625rem 0 0;
      }
      @include media-breakpoint-only(lg) {
        padding: 0 0.625rem 0 0;
      }
    }
    &:hover,
    &:active,
    &:focus,
    &:visited {
      color: #fff;
    }
    &.is-unused {
      letter-spacing: -0.075rem;
    }
    &::after {
      background-size: cover;
      content: '';
      display: inline-block;
      height: 20px;
      position: absolute;
      right: 1rem;
      top: 50%;
      transform: translateY(-50%);
      width: 20px;
    }
  }
  &-accordion-trigger {
    color: #505050;
    display: block;
    font-size: 0.875rem;
    font-weight: 300;
    text-align: center;
  }
  &-baloon {
    display: block;
    font-weight: 700;
    margin: 0 auto;
    text-align: center;
    position: relative;
    width: fit-content;
    &:before,
    &:after {
      background-size: cover;
      display: inline-block;
      content: '';
      height: 28px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 24px;
    }
    &:before {
      left: -1.75rem;
    }
    &:after {
      right: -1.75rem;
    }
    &.is-unused {
      color: #fff;
      &:before {
        left: -1.75rem;
      }
      &:after {
        right: -1.75rem;
      }
    }
  }
  &-fee {
    &-container {
      display: block;
      line-height: 1;
      margin: 0.625rem auto 1rem;
      position: relative;
      text-align: center;
      text-shadow: 2px 2px 0 #fff, -2px 2px 0 #fff, 2px -2px 0 #fff,
        -2px -2px 0 #fff;
      width: fit-content;
      &.is-second,
      &.is-third {
        padding: 0 0 0 6.4vw;
        @include media-breakpoint-up(md) {
          padding: initial;
        }
      }
      &.is-unused {
        color: #fff;
        text-shadow: none;
      }
    }
    &-monthly {
      bottom: 1.6vw;
      left: -3rem;
      position: absolute;
      @include media-breakpoint-up(md) {
        bottom: 0.5rem;
      }
      &.is-first {
        @include media-breakpoint-up(md) {
          left: -2.25rem;
        }
      }
      &.is-second {
        left: -15.467vw; // 58px
        @include media-breakpoint-up(md) {
          left: -3rem;
        }
      }
    }
    &-monthly,
    &-unit {
      font-size: 7.467vw;
      font-weight: 700;
      @include media-breakpoint-up(md) {
        font-size: 1.5rem;
      }
    }
    &-number-large {
      font-size: 24vw;
      font-family: 'Hiragino Sans', 'Hiragino Kaku Gothic ProN', Arial,
        Helvetica, sans-serif;
      font-weight: 700;
      letter-spacing: -1.05rem;
      margin: 0 0.875rem 0 0;
      @include media-breakpoint-up(md) {
        font-size: 5.625rem;
      }
      &.is-second {
        letter-spacing: -0.075rem;
        margin: 0 -0.25rem 0 0;
      }
    }
    &-number-middle {
      font-size: 16vw;
      font-family: 'Hiragino Sans', 'Hiragino Kaku Gothic ProN', Arial,
        Helvetica, sans-serif;
      font-weight: 700;
      @include media-breakpoint-up(md) {
        font-size: 3.75rem;
      }
    }
    &-range {
      bottom: 0.875rem;
      font-size: 1.75rem;
      font-weight: 700;
      position: absolute;
      right: -2rem;
      @include media-breakpoint-up(md) {
        right: -2.125rem;
      }
    }
    &-tax {
      bottom: 1.6vw;
      font-size: 0.75rem;
      font-weight: 700;
      position: absolute;
      right: -2.875rem;
      width: 13.33334vw;
      @include media-breakpoint-only(sm) {
        bottom: 0.5rem;
        right: -2.9875rem;
        width: 50px;
      }
      @include media-breakpoint-up(md) {
        bottom: 0.5rem;
        right: -2.9875rem;
        width: 50px;
      }
      @media screen and (max-width: 320px) {
        font-size: 3.2vw;
        right: -12vw;
        width: 13.333vw;
      }
      @media screen and (max-width: 280px) {
        width: 15vw;
      }
    }
  }
  &-details {
    &-content {
      background: #fff;
      border-radius: 0.625rem;
      margin: 1rem auto 0;
      padding: 1rem;
      position: relative;
      &::before {
        border-color: transparent transparent #fff;
        border-style: solid;
        border-width: 0 0.5rem 0.5rem;
        bottom: initial;
        content: '';
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
        top: -0.5rem;
      }
    }
    &-list-item {
      font-size: 0.6875rem;
      display: block;
    }
  }
  &-details[open] {
    .ordertype-summary::before {
      content: 'プラン詳細を閉じる';
    }
  }
  &-summary {
    cursor: pointer;
    display: block;
    font-size: 0.875rem;
    text-align: center;
    &::before {
      display: block;
      content: 'プラン詳細を見る';
      text-align: center;
    }
    &.is-unused {
      color: #fff;
    }
  }
}

// open keyframe
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}

// qa
.qa {
  &-details {
    @include media-breakpoint-up(md) {
      margin: 0 auto;
      width: 640px;
    }
    .qa-summary {
      border-radius: 0;
    }
  }
  &-details[open] {
    .qa-summary {
      border-radius: 0;
      &::after {
        background: url(./assets/img/icon-minus.svg) 0 center no-repeat;
        background-size: contain;
        height: 0.75rem;
        width: 0.75rem;
        @include media-breakpoint-up(md) {
          background-size: contain;
          height: 0.75rem;
          right: 1.5rem;
          width: 0.75rem;
        }
        @media screen and (max-width: 320px) {
          background-size: contain;
        }
      }
    }
  }
  &-details[open] .qa-content {
    animation: fadeIn 0.5s ease;
    animation-fill-mode: forwards;
    background: #fff;
    border-bottom: 1px solid #dce0e0;
    color: #262626;
    opacity: 0;
    padding: 1.25rem 1.25rem 1.25rem 10.667vw;
    position: relative;
    @include media-breakpoint-up(md) {
      padding: 1.25rem 1.25rem 1.25rem 3rem;
    }
  }
  &-details[open] .qa-content::after {
    color: $secondary;
    content: 'A';
    font-size: 32px;
    font-weight: bold;
    left: 0.625rem;
    position: absolute;
    top: 10px;
    @include media-breakpoint-up(md) {
      left: 0.875rem;
    }
    @media screen and (max-width: 320px) {
      font-size: 7.467vw; // 28px
    }
  }
  &-summary {
    align-items: center;
    background-color: #fff;
    border-bottom: 1px solid #dce0e0;
    cursor: pointer;
    display: flex;
    min-height: 56px;
    font-weight: initial;
    letter-spacing: -0.0125rem;
    overflow: hidden;
    padding: 1vw 8.533vw 1vw 10.667vw;
    position: relative;
    @include media-breakpoint-up(md) {
      padding: 0 3rem 0 3rem;
    }
    &::before {
      color: $primary;
      content: 'Q';
      font-size: 32px;
      font-weight: bold;
      left: 0.5rem;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      @include media-breakpoint-up(md) {
        left: 0.75rem;
      }
      @media screen and (max-width: 320px) {
        font-size: 7.467vw; // 28px
      }
    }
    &::after {
      background: url(./assets/img/icon-plus.svg) 0 0 no-repeat;
      background-size: cover;
      content: '';
      display: inline-block;
      height: 0.75rem;
      position: absolute;
      right: 0.6875rem;
      top: 50%;
      transform: translateY(-50%);
      width: 0.75rem;
      @include media-breakpoint-up(md) {
        background-size: contain;
        height: 0.75rem;
        right: 1.5rem;
        width: 0.75rem;
      }
      @media screen and (max-width: 320px) {
        background-size: contain;
      }
    }
  }
  &-link {
    color: $secondary;
    text-decoration: none;
    transition: opacity 0.3s;
    &:hover {
      color: $secondary;
      opacity: 0.7;
    }
    &:visited,
    &:active,
    &:focus {
      color: $secondary;
    }
  }
  &-category-link {
    color: #262626;
    cursor: pointer;
    display: block;
    font-size: 0.875rem;
    margin: 1rem 0.75rem 0 auto;
    position: relative;
    text-decoration: underline;
    width: fit-content;
    &:visited,
    &:active,
    &:focus {
      color: #262626;
    }
    @include media-breakpoint-only(md) {
      margin: 1rem 4.625rem 0 auto;
    }
    @include media-breakpoint-up(lg) {
      // margin: 1rem calc(100% - 800px + 1.5rem) 0 auto;
      margin: 1rem 184px 0 auto;
    }
    &::after {
      background: url(./assets/img/icon-chevron-up.svg) 50% 50% no-repeat;
      background-size: cover;
      content: '';
      display: inline-block;
      height: calc(0.75rem / 2);
      margin: 0 0 0 0.5rem;
      width: 0.75rem;
    }
  }
}

.instruction {
  &-heading {
    font-size: 1.125rem;
    font-weight: 700;
  }
  &-list {
    line-height: 2;
  }
  &-link {
    color: #006ebd;
    text-decoration: none;
  }
}

// NOTE: css詳細度をあげて上書き
body .section-benefit-footer {
  margin: 0 auto;
  padding: 1.5rem 1rem 2rem;
  width: 100%;
}

body .footer-link-mail {
  @include media-breakpoint-only(md) {
    margin: 0 auto;
    width: 640px;
  }
}

body .footer-image-tel {
  @media screen and (max-width: 374px) {
    max-width: 100%;
  }
}
