@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/_mixins.scss';

* {
  box-sizing: border-box;
}
body {
  color: #262626;
}
body ul,
body ol,
body li {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
body p {
  margin: 0;
  padding: 0;
}
a,
img {
  display: block;
}
img {
  width: 100%;
}
body h1,
body h2,
body h3,
body h4,
body h5,
body h6 {
  margin: 0;
}

$primary: #ffc300;
$secondary: #eb4637;
$grey: #d9d9d9;

.section {
  &-container {
    padding: 2rem 4px; // NOTE: デザインと同様の余白にするため左右4px付与
    &.is-index,
    &.is-description,
    &.is-howto,
    &.is-benefit-list,
    &.is-sns,
    &.is-magazine {
      background: rgba(255, 249, 230, 1);
      // NOTE: ページ全体を包括する親要素があるためマイナスマージンとcalcで調整する必要あり
      margin: 0 -12px;
      padding: 2rem calc(4px + 12px); // NOTE: デザインと同様の余白にするため左右4px付与
      width: calc(100% + 24px);
      @include media-breakpoint-up(lg) {
        padding: 2rem 2.5rem;
      }
    }
    &.is-description {
      padding-top: 0;
    }
    &.is-howto {
      @include media-breakpoint-down(md) {
        padding: 2rem calc(4px + 12px) 0; // NOTE: SPサイズの場合だけ下方向にpadding重複があるため調整
      }
    }
    &.is-firstview {
      background: $primary;
      // NOTE: ページ全体を包括する親要素があるためマイナスマージンとcalcで調整する必要あり
      margin: 0 -12px;
      padding: 0 12px 12px;
      width: calc(100% + 24px);
      @include media-breakpoint-down(md) {
        padding: 0 12px 5rem;
      }
      @include media-breakpoint-only(md) {
        padding: 0 12px 2rem;
      }
      @include media-breakpoint-up(lg) {
        padding: 0 12px 3rem;
      }
      @include media-breakpoint-up(xl) {
        padding: 0 12px 4rem;
      }
    }
    &.is-campaign {
      background: $primary;
      // NOTE: ページ全体を包括する親要素があるためマイナスマージンとcalcで調整する必要あり
      margin: 0 -12px;
      padding: 24px 0 24px;
      width: calc(100% + 24px);
    }
  }
  &-heading {
    font-size: 1.5rem;
    font-weight: 700;
    margin: 0 auto 2rem;
    @include media-breakpoint-only(lg) {
      font-size: 1.5rem;
    }
    @include media-breakpoint-only(xl) {
      font-size: 1.75rem;
    }
    @include media-breakpoint-up(xxl) {
      font-size: 2rem;
    }
    &.is-logo {
      align-items: center;
      display: flex;
      justify-content: center;
      margin: 0 auto;
      min-height: 32px;
      padding: 0.5rem 0;
      width: 100%;
      &::after {
        display: none;
      }
    }
    &.is-howto {
      margin: 0 auto 3rem;
    }
    &.is-campaign {
      font-weight: 700;
      margin: 0 auto;
      text-align: center;
      &::after {
        display: none;
      }
      .section-heading-subitem {
        display: block;
        font-size: 1rem;
      }
      .section-heading-item {
        display: block;
        font-size: 1.5rem;
        margin: 0.5rem auto 0;
      }
    }
    &-logo {
      min-height: 2.25rem;
      width: auto;
      @include media-breakpoint-up(lg) {
        min-height: 2.5rem;
        width: 16.25rem;
      }
    }
    &::after {
      background: $primary;
      bottom: -0.25rem;
      content: '';
      display: inline-block;
      height: 2px;
      left: 50%;
      position: absolute;
      transform: translateX(-50%);
      width: 160px;
    }
  }
  &-subheading-sns {
    margin: 0 auto 1rem;
    width: 84px;
    img {
      width: 100%;
    }
  }
  &-benefit-footer {
    background: #363232;
    color: #fff;
    // NOTE: ページ全体を包括する親要素があるためマイナスマージンとcalcで調整する必要あり
    margin: 0 -12px;
    padding: 1.5rem calc(4px + 12px) 2rem; // NOTE: デザインと同様の余白にするため左右4px付与
    width: calc(100% + 24px);
  }
}
.index {
  &-box {
    background-color: #fff;
    padding: 1.25rem;
  }
  &-title {
    font-weight: bold;
    text-align: center;
  }
  &-list {
    text-align-last: left;
    text-decoration-line: underline;
    line-height: 1.75;
  }
  &-link-color {
    color: #333;
  }
}
.description {
  text-align: center;
  font-size: 14px;
  @include media-breakpoint-up(lg) {
    font-size: 16px;
  }
}
.firstview {
  &-heading-container {
    align-items: center;
    background: url(./assets/img/img-bg-header.svg) no-repeat 0 0;
    background-size: cover;
    display: flex;
    justify-content: center;
    text-align: center;
    margin: 0 -12px;
    min-height: 84px;
    width: calc(100% + 24px);
    @include media-breakpoint-up(lg) {
      align-items: initial;
      background: url(./assets/img/img-bg-header-pc.svg) no-repeat 0 0;
      background-position: center;
    }
  }
  &-heading-sub {
    align-items: center;
    background: url(./assets/img/img-ribbon-header.svg) no-repeat 0 0;
    background-size: cover;
    color: #fff;
    display: inline-flex;
    font-size: 0.9375rem;
    font-weight: 700;
    min-height: 24px;
    min-width: 230px;
    justify-content: center;
  }
  &-heading-main {
    display: block;
    font-size: 1.25rem;
    font-weight: 700;
    margin: 12px auto 0;
    text-shadow: 2px 2px 0 #fff, -2px -2px 0 #fff, -2px 2px 0 #fff,
      2px -2px 0 #fff, 0px 2px 0 #fff, 0 -2px 0 #fff, -2px 0 0 #fff,
      2px 0 0 #fff;
    @include media-breakpoint-up(lg) {
      font-size: 1.5rem;
      margin: 0.5rem auto 0;
    }
    @media screen and (max-width: 320px) {
      font-size: 5.33334vw;
    }
  }
  &-visual-container {
    background: url(./assets/img/img-mainvisual-sp.png) no-repeat 50% 50%;
    background-size: cover;
    height: 11.25rem;
    // NOTE: ページ全体を包括する親要素があるためマイナスマージンとcalcで調整する必要あり
    margin: 0 -12px 12px;
    padding: 2rem calc(4px + 12px); // NOTE: デザインと同様の余白にするため左右4px付与
    width: calc(100% + 24px);
    @include media-breakpoint-only(lg) {
      background: url(./assets/img/img-mainvisual-pc.png) no-repeat 50% 50%;
      background-size: cover;
      height: 18.625rem;
    }
    @include media-breakpoint-up(xl) {
      background: url(./assets/img/img-mainvisual-pc.png) no-repeat 50% 50%;
      background-size: cover;
      height: 22.625rem;
    }
  }
  &-description-container {
    background: $primary;
  }
  &-description {
    background: #ffe699;
    color: #4f4f4f;
    font-size: 0.75rem;
    padding: 8px;
    text-align: left;
    @include media-breakpoint-only(lg) {
      font-size: 0.875rem;
    }
    @include media-breakpoint-only(xl) {
      font-size: 0.9375rem;
    }
    @include media-breakpoint-up(xxl) {
      font-size: 1rem;
    }
    &::before {
      background: url(./assets/img/img-gritter-left.svg) no-repeat 0 0;
      background-size: contain;
      bottom: -0.5rem;
      content: '';
      display: inline-block;
      height: 24px;
      left: -0.5rem;
      position: absolute;
      width: 27px;
    }
    &::after {
      background: url(./assets/img/img-gritter-right.svg) no-repeat 0 0;
      background-size: contain;
      content: '';
      display: inline-block;
      height: 24px;
      right: -0.5rem;
      position: absolute;
      top: -0.5rem;
      width: 27px;
    }
    @include media-breakpoint-up(lg) {
      margin: 0 auto;
      padding: 1rem 1.25rem;
      width: 43.4375rem;
    }
  }
  &-gritter-left {
    bottom: -0.5rem;
    left: -0.5rem;
    position: absolute;
  }
  &-gritter-right {
    right: -0.5rem;
    position: absolute;
    top: -0.5rem;
  }
  &-campaign-thumbnail-container {
    position: relative;
  }
  &-campaign-thumbnail-container-item {
    position: absolute;
    @include media-breakpoint-down(md) {
      left: 50%;
      right: 0;
      transform: translateX(-50%);
      top: -7.5rem;
    }
    @include media-breakpoint-only(md) {
      top: -4rem;
    }
    @include media-breakpoint-up(lg) {
      left: 0;
      right: 0;
      top: -5rem;
    }
    @include media-breakpoint-up(xl) {
      left: 0;
      right: 0;
      top: -6.5rem;
    }
    @include media-breakpoint-up(xxl) {
      left: 0;
      right: 0;
      top: -7.5rem;
    }
  }
}

.howto {
  &-card-container {
    @include media-breakpoint-up(lg) {
      gap: 1rem;
    }
  }
  &-card {
    background: #fff;
    border: 1px solid $grey;
    border-radius: 0.25rem;
    margin: 0 auto 1.25rem;
    padding: 1rem;
    position: relative;
    width: 100%;
    @include media-breakpoint-up(xl) {
      margin: 0 0 1.25rem;
      width: calc((100% / 3) - 1.08334%);
    }
    @include media-breakpoint-only(lg) {
      font-size: 0.875rem;
      margin: 0 0 1.25rem;
      width: calc((100% / 3) - 1.33334%);
    }
    @include media-breakpoint-only(xl) {
      font-size: 0.9375rem;
    }
    @include media-breakpoint-up(xxl) {
      font-size: 1rem;
    }
    &::before {
      background: none;
      background-size: cover;
      content: '';
      display: inline-block;
      height: 20px;
      left: 50%;
      position: absolute;
      top: -10px;
      transform: translateX(-50%);
      width: 55px;
    }
    &.is-first::before {
      background: url(./assets/img/img-step-first.svg) no-repeat 0 0;
    }
    &.is-second::before {
      background: url(./assets/img/img-step-second.svg) no-repeat 0 0;
    }
    &.is-third::before {
      background: url(./assets/img/img-step-third.svg) no-repeat 0 0;
    }
    &:last-of-type {
      margin: 0 auto calc(1.25rem / 2);
      @include media-breakpoint-up(lg) {
        margin: 0 auto 1.25rem;
      }
    }
  }
  &-image-container {
    margin: 0 auto 0.5rem;
    width: 100%;
  }
  &-image-item {
    display: block;
    width: 100%;
  }
  &-notice-item {
    color: #808080;
    font-size: 0.75rem;
  }
}

.benefit-accordion {
  &-checkbox {
    display: none;
    :checked ~ .accordion-item {
      max-height: 800px;
    }
  }
  &-item {
    color: #4f4f4f;
    font-size: 0.875rem;
    height: 80px;
    max-height: 80px;
    overflow: hidden;
    margin: 0 auto 3rem;
    transition: all 0.5s;
  }
  &-trigger {
    align-items: center;
    bottom: 48px;
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0) 5%,
      white 40%,
      white 100%
    );
    color: #5296a5;
    height: 64px;
    font-size: 0.75rem;
    justify-content: center;
    left: 50%;
    padding: 0.5rem 0 0;
    position: absolute;
    transform: translateX(-50%);
    transition: all 0.5s;
    width: 100%;
    @include media-breakpoint-only(lg) {
      font-size: 0.875rem;
    }
    @include media-breakpoint-only(xl) {
      font-size: 0.9375rem;
    }
    @include media-breakpoint-up(xxl) {
      font-size: 1rem;
    }
  }
  &-chevron {
    display: inline-block;
    margin: 0 0 0 0.25rem;
    transition: all 0.3s;
  }
}

.benefit-accordion-checkbox:checked + .benefit-accordion-trigger {
  bottom: 2rem;
}

.benefit-accordion-checkbox:checked
  + .benefit-accordion-trigger
  > .benefit-accordion-chevron {
  transform: rotate(180deg);
}

.benefit-accordion-checkbox:checked ~ .benefit-accordion-item {
  height: fit-content;
  margin: 0 auto 3rem;
  max-height: 1000px;
  transition: all 0.5s;
}

.benefit-list {
  &-container {
    display: flex;
    flex-wrap: wrap;
    @include media-breakpoint-up(lg) {
      gap: 0 1rem;
    }
  }
  &-card {
    background: #fff;
    border: 1px solid $grey;
    border-radius: 0.25rem;
    margin: 0 0 1rem;
    padding: 1.25rem;
    position: relative;
    width: 100%;
    @include media-breakpoint-up(xl) {
      width: calc((100% / 3) - 1.08334%);
    }
    @include media-breakpoint-only(lg) {
      width: calc((100% / 3) - 1.33334%);
    }
    &:last-of-type {
      margin: 0;
      @include media-breakpoint-up(md) {
        margin: 0 0 1rem;
      }
    }
    &.is-new {
      &::before {
        background: $secondary;
        border-radius: 9999px;
        color: #fff;
        content: 'NEW';
        display: inline-block;
        font-size: 0.625rem;
        font-weight: 600;
        left: 0.125rem;
        padding: 0.125rem 0.25rem;
        position: absolute;
        top: 0.125rem;
      }
    }
    &.is-due-soon {
      &::before {
        background: #5b5b5b;
        border-radius: 9999px;
        color: #fff;
        content: 'まもなく終了';
        display: inline-block;
        font-size: 0.625rem;
        font-weight: 600;
        left: 1.125rem;
        padding: 0.125rem 0.25rem;
        position: absolute;
        top: 1.125rem;
      }
    }
  }
  &-card-thumbnail {
    display: block;
    height: 200px;
    object-fit: contain;
    width: 100%;
  }
  &-card-thumbnail-container {
    // NOTE: 定形の画像が入るとは限らない仕様という事なので
    //       横長画像の場合に余白が目立つため薄いボーダー付与
    border: 1px solid #ebebeb;
  }
  &-card-heading-container {
    display: block;
    margin: 0 auto 0.625rem;
    text-align: center;
    width: 100%;
  }
  &-card-heading,
  &-card-subheading {
    font-size: 1rem;
  }
  &-card-heading {
    font-weight: 700;
    margin: 0 auto 0.5rem;
  }
  &-button {
    align-items: center;
    border: 1px solid rgba(212, 212, 212, 1);
    border-radius: 9999px;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
      0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    color: #333;
    display: inline-flex;
    font-weight: 700;
    justify-content: center;
    line-height: 1;
    margin: 0 auto;
    min-height: 36px;
    padding: 0 1.25rem;
    text-align: center;
    text-decoration: none;
    &:visited,
    &:focus,
    &:active {
      color: #333;
    }
    @include media-breakpoint-up(lg) {
      padding: 0 0.25rem;
      width: 100%;
    }
    @media screen and (max-width: 320px) {
      font-size: 4.26667vw;
    }
  }
  &-button-container {
    position: relative;
    text-align: center;
    z-index: 30;
  }
}

.link {
  &-magazine {
    display: block;
    width: 100%;
    @include media-breakpoint-up(lg) {
      margin: 0 auto;
      width: 22.5rem;
    }
  }
}
.campaign-thumbnail {
  &-container {
    padding: 0 12px;
    width: 100%;
    @include media-breakpoint-up(lg) {
      margin: 0 auto;
      width: 22.5rem;
    }
  }
  &-item {
    display: block;
    width: 100%;
  }
  &-caption {
    background: #fff;
    color: #4f4f4f;
    font-size: 0.75rem;
    padding: 0.75rem 0.5rem;
    @include media-breakpoint-only(lg) {
      font-size: 0.875rem;
    }
    @include media-breakpoint-only(xl) {
      font-size: 0.9375rem;
    }
    @include media-breakpoint-up(xxl) {
      font-size: 1rem;
    }
  }
}

.footer {
  &-container {
    @include media-breakpoint-up(lg) {
      margin: 0 auto;
      width: 22.5rem;
    }
  }
  &-heading {
    font-size: 1.25rem;
    font-weight: 700;
    margin: 0 auto 0.5rem;
    text-align: center;
    @include media-breakpoint-only(lg) {
      font-size: 1.25rem;
    }
    @include media-breakpoint-only(xl) {
      font-size: 1.5rem;
    }
    @include media-breakpoint-up(xxl) {
      font-size: 1.75rem;
    }
  }
  &-description {
    font-size: 0.75rem;
    text-align: left;
    @include media-breakpoint-only(md) {
      text-align: center;
    }
    @include media-breakpoint-only(lg) {
      font-size: 0.875rem;
    }
    @include media-breakpoint-only(xl) {
      font-size: 0.9375rem;
    }
    @include media-breakpoint-up(xxl) {
      font-size: 1rem;
    }
  }
  &-link-mail {
    align-items: center;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    color: #373232;
    display: flex;
    height: 50px;
    font-weight: 700;
    justify-content: center;
    line-height: 1;
    text-decoration: none;
    &:hover,
    &:visited,
    &:active {
      color: #373232;
    }
  }
  &-link-mail-icon {
    display: inline-block;
    height: auto;
    margin: 0 0.5rem 0 0;
    width: 22px;
  }
  &-link-qa {
    color: $primary;
    display: inline-block;
    font-size: 0.75rem;
    margin: 0 auto 0.75rem;
    @include media-breakpoint-only(lg) {
      font-size: 0.875rem;
    }
    @include media-breakpoint-only(xl) {
      font-size: 0.9375rem;
    }
    @include media-breakpoint-up(xxl) {
      font-size: 1rem;
    }
    &:hover,
    &:visited,
    &:active {
      color: $primary;
    }
  }
  &-phonetime {
    font-size: 0.6875rem;
  }
}
// @media screen and (min-width: 769px) {
//   .is-pc {
//     display: block;
//     padding: 0 5rem;
//   }
//   .is-sp {
//     display: none;
//   }
// }
// @media screen and (max-width: 767px) {
//   .is-pc {
//     display: none;
//   }
//   .is-sp {
//     display: block;
//     max-width: 327px;
//     width: 100%;
//   }
// }
@include media-breakpoint-up(lg) {
  .is-pc {
    display: block;
    padding: 0 5rem;
  }
  .is-sp {
    display: none;
  }
}
@include media-breakpoint-down(md) {
  .is-pc {
    display: none;
  }
  .is-sp {
    display: block;
    max-width: 327px;
    width: 100%;
  }
}
@include media-breakpoint-only(md) {
  .is-pc {
    display: block;
    padding: 0 5rem;
  }
  .is-sp {
    display: none;
  }
}

body .section-benefit-footer {
  &.is-benefit {
    // NOTE: faqのscssと競合が起きているため暫定対応
    background: #363232;
    color: #fff;
    // NOTE: ページ全体を包括する親要素があるためマイナスマージンとcalcで調整する必要あり
    margin: 0 -12px;
    padding: 1.5rem calc(4px + 12px) 2rem; // NOTE: デザインと同様の余白にするため左右4px付与
    width: calc(100% + 24px);
    .footer-tel-image {
      display: inline-block;
      width: 100%;
    }
  }
}